import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './Footer.css';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="social-media">
        <a href="https://x.com"><FontAwesomeIcon icon={faTwitter} /></a>
        <a href="https://instagram.com"><FontAwesomeIcon icon={faInstagram} /></a>
        <a href="mailto:info@horusailabs.com"><FontAwesomeIcon icon={faEnvelope} /></a>
      </div>
      <p>&copy; 2024 Horus AI Labs. All Rights Reserved.</p>
    </footer>
  );
};