import './ActionButtons.css';

const ActionButtons = () => {

  return (
    <div className="action-buttons">
      <a href="https://calendar.app.google/6ppPSqrEcMNAnq387" target="_blank" rel="noopener noreferrer">
        <button className="demo-btn">Book a Demo</button>
      </a>
      <a href="/playground.html" target="_blank" rel="noopener noreferrer">
        <button className="playground-btn">Playground</button>
      </a>
    </div>
  );
}

export default ActionButtons;