import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import Pricing from './Pricing';    // Pricing page component
import Footer from './Footer.js';
import Navbar from './Nav.js';
import './App.css';

const App = () => {
  return (
    <ParallaxProvider>
      <div className="App">
        <header>
          <Navbar />
        </header>
        <Router>
          <div className="main-content">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/pricing" element={<Pricing />} />
            </Routes>
          </div>
        </Router>
        <Footer />
     </div>
   </ParallaxProvider>
  );
}

export default App;
