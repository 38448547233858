import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faChevronDown, faBook, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faTwitter, faGithub } from '@fortawesome/free-brands-svg-icons';
import ActionButtons from './ActionButtons.js';
import './Nav.css';
import { useMediaQuery } from 'react-responsive';


//          <ul className="dropdown">
//            <li><FontAwesomeIcon icon={faSearch} /> Search</li>
//            <li><FontAwesomeIcon icon={faBook} /> Pricing</li>
//          </ul>


//<li className="nav-item">
//          <a href="/pricing">Pricing</a>
//        </li>
//        <li className="nav-item"><a href="#">Develop</a></li>
//        <li className="nav-item"><a href="#">Blogs</a></li>
//        <li className="nav-item"><a href="#">About Us</a></li>

const Navbar = () => {
  // Define breakpoints for responsive design
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <nav className="navbar">
      <a href="/">
        <div className="logo">
          <img src="logo-no-background.png" alt="Horus AI"></img>
        </div>
      </a>
      <ul className="nav-links">
      </ul>
      {!isMobile && (
        <ActionButtons />
      )}
    </nav>
  );
};

export default Navbar;
