import React, { useEffect, useState, useRef } from 'react';
import { FaSearch, FaVideo, FaClock, FaShieldAlt, FaChartBar, FaLayerGroup } from 'react-icons/fa';
import './ProductFeatures.css';

const ProductFeatures = () => {
  useEffect(() => {
      const revealElements = document.querySelectorAll('.reveal');

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add('visible');
              observer.unobserve(entry.target); // Stops observing once the animation is triggered
            }
          });
      }, { threshold: 0.2 }); // Triggers when 20% of the card is visible

      revealElements.forEach(element => {
        observer.observe(element);
      });

      return () => {
        revealElements.forEach(element => {
          observer.unobserve(element);
        });
      };
    }, []);

  return (
    <div className="product-features">
      <h2 className="feature-header">Powerful features that set us apart</h2>
      <div className="feature-cards-container">
        <div className="feature-card reveal">
          <FaSearch className="feature-icon" />
          <h3>Smart Video Search</h3>
          <p>Quickly search within long-form videos using natural language prompts, making manual scrubbing a thing of the past.</p>
        </div>
        <div className="feature-card reveal">
          <FaVideo className="feature-icon" />
          <h3>AI-Powered Summaries</h3>
          <p>Get automatic video highlights and summaries tailored to your needs for faster insights and reviews.</p>
        </div>
        <div className="feature-card reveal">
          <FaClock className="feature-icon" />
          <h3>Time Efficiency</h3>
          <p>Save hours by automatically pinpointing key moments and skipping irrelevant content in seconds.</p>
        </div>
        <div className="feature-card reveal">
          <FaShieldAlt className="feature-icon" />
          <h3>Data Security</h3>
          <p>Our platform ensures that sensitive data stays protected, complying with industry-leading privacy standards.</p>
        </div>
        <div className="feature-card reveal">
          <FaChartBar className="feature-icon" />
          <h3>Analytics Integration</h3>
          <p>Gain insights from your video content with integrated analytics to drive data-informed decisions.</p>
        </div>
        <div className="feature-card reveal">
          <FaLayerGroup className="feature-icon" />
          <h3>Scalable Infrastructure</h3>
          <p>Handle large volumes of video data seamlessly with our cloud-ready, scalable solution.</p>
        </div>
      </div>
    </div>
  );
};

export default ProductFeatures;
