import React, { useEffect } from 'react';
import { FaCogs, FaCamera, FaChartPie, FaUserShield } from 'react-icons/fa';
import './Benefits.css';
import { useMediaQuery } from 'react-responsive';


const SurveillanceBenefits = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view');
        }
      });
    });

    const textBlocks = document.querySelectorAll('.benefit-text-block, .benefit-icon, .line');
    textBlocks.forEach((textBlock) => observer.observe(textBlock));

    return () => {
      textBlocks.forEach((textBlock) => observer.unobserve(textBlock));
    };
  }, []);

  return (
    <div className="surveillance-benefits-line">
      <h2 className="feature-header">Domain-Specific Model</h2>
      <div className="benefit-row">
        <div className="icon-line">
          <FaCogs className="benefit-icon" />
          {!isMobile && (<div className="line"></div>)}
        </div>
        <div className="benefit-text-block">
          <h3>Custom Model Optimization</h3>
          <p>Optimized for surveillance, our models deliver precise detection of motion and anomalies in footage.</p>
        </div>
      </div>

      <div className="benefit-row">
        <div className="icon-line">
          <FaCamera className="benefit-icon" />
          {!isMobile && (<div className="line"></div>)}

        </div>
        <div className="benefit-text-block">
          <h3>Efficient Object Detection</h3>
          <p>Identify people, vehicles, and objects swiftly, perfect for real-time surveillance use cases.</p>
        </div>
      </div>

      <div className="benefit-row">
        <div className="icon-line">
          <FaChartPie className="benefit-icon" />
          {!isMobile && (<div className="line"></div>)}
        </div>
        <div className="benefit-text-block">
          <h3>Advanced Video Analytics</h3>
          <p>Leverage tools like heatmaps and behavioral analysis to gain deeper insights from video data.</p>
        </div>
      </div>

      <div className="benefit-row">
        <div className="icon-line">
          <FaUserShield className="benefit-icon" />
        </div>
        <div className="benefit-text-block">
          <h3>Enhanced Privacy & Security</h3>
          <p>Maintain privacy compliance while delivering accurate video analysis for secure environments.</p>
        </div>
      </div>
    </div>
  );
};

export default SurveillanceBenefits;
