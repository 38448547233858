import React, { useEffect, useRef } from 'react';
import './Pricing.css';

const Pricing = () => {
//  const cardsRef = useRef([]);

//  useEffect(() => {
//    const observer = new IntersectionObserver(
//      (entries) => {
//        entries.forEach((entry) => {
//          if (entry.isIntersecting) {
//            entry.target.classList.add('visible');
//            observer.unobserve(entry.target);
//          }
//        });
//      },
//      { threshold: 0.1 }
//    );
//
//    cardsRef.current.forEach((card) => {
//      observer.observe(card);
//    });
//  }, []);

  return (
    <section className="pricing-container">
      <h1 className="pricing-header">One Time Indexing, then Pay as you go...</h1>
      <div className="pricing-cards">
          {/* Free Tier Card */}
          <div className="pricing-card free-tier">
            <h2 className="card-title">Free Tier</h2>
            <p className="card-description">Indexing and Search are free.</p>
            <p className="card-limit">Limit: 100 API calls per day.</p>
          </div>
          {/* Developer Mode Card */}
          <div className="pricing-card developer-mode">
            <h2 className="card-title">Developer Mode</h2>
            <p className="card-description">
              One-time pricing for indexing: ~$2.5 per hour<br />
              Subsequent pricing for prompt-based search: $0.25 per hour of searched content
            </p>
            <p className="card-limit">Limit: 5,000 API calls per day.</p>
          </div>
          {/* Enterprise Card */}
          <div className="pricing-card enterprise">
            <h2 className="card-title">Enterprise</h2>
            <p className="card-description">
              One-time indexing price: $2 per hour<br />
              Subsequent pricing for prompt-based search: $0.2 per hour of searched content
            </p>
            <p className="card-limit">Minimum: 10,000 API calls per day.</p>
          </div>
      </div>
    </section>
  );
};

export default Pricing;
