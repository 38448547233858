import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { motion } from 'framer-motion';
import './HomePage.css'; // Custom styles
import ProductDemo from './ProductDemo.js';
import ProductFeatures from './ProductFeatures.js';
import SurveillanceBenefits from './Benefits.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionButtons from './ActionButtons.js';
import GetInTouch from './GetInTouch.js';

const HomePage = () => {
  return (
      <div>
        <section className="hero">
          <motion.div className="hero-content" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            <h1>Your AI-Powered Platform for Video Search & Highlights</h1>
            <p>Find relevant scenes within long-form videos using AI.</p>
            <ActionButtons />
          </motion.div>
        </section>

        {/* Add Parallax sections */}
        <section className="product-section">
          <motion.div className="product-content" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 2 }}>
          <ProductFeatures />
          </motion.div>
        </section>

        <section className="how-it-works">
        <motion.div className="product-content" initial={{opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 2 }}>
          <ProductDemo />
          </motion.div>
        </section>

        <section className="motivation">
        <motion.div className="product-content" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 2 }}>
          <SurveillanceBenefits />
          </motion.div>
        </section>
        <GetInTouch />
     </div>
  );
}

export default HomePage;